import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import * as Styles from "../theme/home.css";
import styled from "styled-components";
import media from "../theme/media";

const Wrapper = styled.div`
display: flex;
justify-content: center;
`
const MainHeader = styled.span`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 90px;
  line-height: 82px;
  color: #e84545;
  padding-bottom: 20px;
  ${media.tablet`font-size: 25px;`};
  ${media.tablet`line-height:37.5px;`};
  ${media.tablet`padding-bottom: 0;`};
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <Styles.Container>
      <Wrapper>
      <MainHeader>404</MainHeader>
      </Wrapper>
    </Styles.Container>
  </Layout>
)

export default NotFoundPage
